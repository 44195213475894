/*eslint no-constant-condition: ["error", { "checkLoops": false }]*/
export const PasswordGenerator = {
    _pattern: /[a-zA-Z0-9]/,

    _getRandomByte: function() {
        // http://caniuse.com/#feat=getrandomvalues
        if (window.crypto && window.crypto.getRandomValues) {
            let result = new Uint8Array(1);
            window.crypto.getRandomValues(result);
            return result[0];
        } else if (window.msCrypto && window.msCrypto.getRandomValues) {
            let result = new Uint8Array(1);
            window.msCrypto.getRandomValues(result);
            return result[0];
        } else {
            return Math.floor(Math.random() * 256);
        }
    },

    generate: function(length, pattern) {
        let result = '';
        const excludeAmbiguousChars = /^[^#Il10Oo]*$/;
        while (!pattern.test(result) || !excludeAmbiguousChars.test(result)) {
            result = Array.apply(null, { length: length })
                .map(function() {
                    let result;
                    while (true) {
                        result = String.fromCharCode(this._getRandomByte());
                        if (this._pattern.test(result)) {
                            return result;
                        }
                    }
                }, this)
                .join('');
        }
        return result;
    },
};
