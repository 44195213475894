export const urlOrganizationCheck = (vm, redirectToRouteName = '') => {
  const isInWrongOrganization =
  vm.$route.query?.organization &&
  vm.$route.query.organization != vm.$store.state.user.companyId;

  if (isInWrongOrganization) {
    vm.isBadRoute = true;
    vm.$root.showMessage(
      'Wrong Company',
      'The link you have clicked on is for an item in another company. Refer to the email for the company name.',
      () => null,
      null,
      'OK',
      null,
      true,
    );
    if(redirectToRouteName != '') {
     vm.$router.replace({ name: redirectToRouteName });
    }
    return false;
  }
  return true;
}

export const phantomAccessCheck = (vm, pageName) => {
  if (vm.$store.state.user.isPhantomUser) {
    vm.isBadRoute = true;
    vm.$root.showMessage(
      'Cannot Access Page as Group Administrator',
      `To access the <strong>${pageName}</strong> page in the employee's section<br>you must be in your home company or signed in to this company as a regular user.`,
      () => null,
      null,
      'OK',
      null,
      true,
    );
    vm.$router.replace({ name: 'UserHome' });
    return false;
  }
  return true;
}

export const configureUser = (vm, data) => {
  const user = {
    id: data.id,
    firstName: data.firstName,
    lastName: data.lastName,
    username: data.username,
    email: data.email,
    type: data.type,
    companyName: data.companyName,
  };
  if (user.type != vm.$constants.USER_TYPES.INTERNAL_ADMIN) {
    user.companyId = data.companyId;
    user.isSafetyRep = data.isSafetyRep;
    user.isManager = data.isManager;
    user.isSupervisor = data.isSupervisor;
    user.isGroupAdministrator = data.isGroupAdministrator;
    user.isPhantomUser = data.isPhantomUser;
    user.acceptedTermsOfService = data.acceptedTermsOfService;
    user.requirePasswordReset = data.requirePasswordReset;
    user.isFreeSubscriptionType = data.isFreeSubscriptionType;
    user.createTagsOnTheFly = data.createTagsOnTheFly;
    user.isSchedulerEnabled = data.isSchedulerEnabled;
    user.is1LifeMobileSettingEnabled = data.is1LifeMobileSettingEnabled;
    user.companyGroupId = data.companyGroupId;
  }
  return user;
};

export const logInUser = (vm) => {
  vm.$store.dispatch('startSessionCounter');
  // internal admin redirect
  if (vm.$store.getters.user.type == vm.$constants.USER_TYPES.INTERNAL_ADMIN) {
    if (vm.$route.query.redirect) {
      const link = vm.$router.resolve({
        name: vm.$route.query.redirect,
      });
      if (link) {
        vm.$router.replace({ name: vm.$route.query.redirect });
        return;
      }
    }
    vm.$router.replace({
      name: 'CompanyList',
    });
    return;
  }
  // web admin and employee redirect
  vm.$store.commit('updateSelectedCompany', {
    id: vm.$store.getters.user.companyId,
    name: vm.$store.getters.user.companyName,
    status: 1,
    companyGroupId: vm.$store.getters.user.companyGroupId,
  });
  if (vm.$route.params.redirect) {
    const redirect = vm.$route.params.redirect;
    vm.$router.replace({
      name: redirect.name,
      params: redirect.params,
      query: redirect.query,
    });
    return;
  }
  if (vm.$route.query.redirect) {
    const query = JSON.parse(JSON.stringify(vm.$route.query));
    delete query.redirect;
    vm.$router.replace({
      name: vm.$route.query.redirect,
      params: query,
      query: query,
    });
    return;
  }
  // the reload params is used to refresh the page for rendering the menu properly
  vm.$router.replace({ name: 'UserHome', params: { reload: true } });
};
