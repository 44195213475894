<template>
  <v-col>
    <v-row>
      <v-col class="text-h6 pb-4 black--text"> Credentials </v-col>
      <v-col align="right">
        <v-card
          v-if="isCredentialsCopied"
          class="mt-2 green--text"
          elevation="0"
        >
          Copied
        </v-card>
        <v-btn
          v-if="!isCredentialsCopied"
          @click="copyCredentialsToUsersClipboard"
          class="mr-0 px-1"
          text
        >
          COPY
          <v-icon class="ml-2"> mdi-content-copy </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col class="shrink"> Username </v-col>
      <v-col
        :style="{ 'word-break': 'break-word' }"
        class="text-subtitle-1 font-weight-medium"
      >
        {{ userData.username }}
      </v-col>
    </v-row>

    <v-row>
      <v-col class="shrink mt-2"> Password </v-col>
      <v-col align="center">
        <v-row>
          <v-col class="d-flex flex-row">
            <v-text-field
              v-model.trim="password"
              :append-icon="isEyeHidden ? '' : passwordIcon"
              :disabled="isUserDisabled"
              :placeholder="passwordPlaceholder"
              :rules="password ? rules.password : [true]"
              :type="isPasswordDisplayed ? 'text' : 'password'"
              @click:append="toggleIsPasswordDisplayed"
              @keydown.space.prevent
              @keyup="detectIfRandomPassword"
              dense
              outlined
              style="min-height: 80px"
            >
            </v-text-field>
            <a
              v-if="!isUserDisabled"
              @click="generatePassword"
              class="mt-2 ml-1"
            >
              <v-icon color="msaBlue">mdi-cached</v-icon>
            </a>
          </v-col>
        </v-row>

        <v-row class="mt-n6">
          <v-col align="start">
            <v-checkbox
              v-if="userData.email"
              v-model="isCredentialsBeingSentToEmail"
              :disabled="isUserDisabled || !isSendCredentialsDisabled"
              :value="isSendCredentialsDisabled ? '' : false"
              class="mt-n2"
              color="msaBlue"
              label="Send Credentials"
            ></v-checkbox>
            <v-btn
              :class="styles.sendCredentialsClass"
              :disabled="isUserDisabled || isSaveButtonDisabled"
              @click="save()"
              elevation="0"
            >
              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { PasswordGenerator } from '@/plugins/PasswordGenerator.js';

export default {
  name: 'CredentialsSection',
  props: {
    userInformation: Object,
    isUserDisabled: Boolean,
    companyIdFromRoute: Number,
  },
  data() {
    return {
      styles: {
        sendCredentialsClass: ['msaBlue', 'white--text'],
        credentialsRightCols: '',
        linkColour: 'msaBlue--text',
      },
      rules: {
        password: [(v) => this.validatePassword(v)],
      },
      errors: {
        password: [],
      },
      copyTimeout: null,
      isCredentialsBeingSentToEmail: false,
      isCredentialsCopied: false,
      isPasswordDisplayed: true,
      isPasswordRandomlyGenerated: false,
      isPasswordTextFieldFocused: false,
      password: '',
      userData: {
        username: '',
        temporaryPasswordPeek: '',
        status: 0,
      },
    };
  },
  computed: {
    passwordPlaceholder() {
      if (this.userData.temporaryPasswordPeek) {
        return '******';
      }

      if (this.password == '') {
        return '******';
      }

      return '';
    },
    isSendCredentialsDisabled() {
      if (
        ((this.isPasswordRandomlyGenerated && !this.isSaveButtonDisabled) ||
          !this.isSaveButtonDisabled) &&
        typeof this.validateEmail(this.userData.email) == 'boolean'
      ) {
        return true;
      }
      return false;
    },
    passwordIcon() {
      const result = this.isPasswordDisplayed ? 'mdi-eye' : 'mdi-eye-off';
      return result;
    },
    isEyeHidden() {
      if (this.password.length == 0) {
        return true;
      }

      return false;
    },
    isSaveButtonDisabled() {
      if (this.password == this.userData.temporaryPasswordPeek) {
        return true;
      }
      if (this.validatePassword(this.password).length) {
        return true;
      }

      return false;
    },
  },
  watch: {
    isCredentialsCopied(val) {
      // Clear existing timeout if it exists
      if (this.copyTimeout) {
        clearTimeout(this.copyTimeout);
        this.copyTimeout = null; // Reset the reference
      }
      // If isCredentialsCopied is true, start a new timeout
      if (val) {
        this.copyTimeout = setTimeout(() => {
          if (this.isCredentialsCopied) {
            this.isCredentialsCopied = false;
          }
          this.copyTimeout = null; // Reset the reference after timeout function runs
        }, 3000);
      }
    },
    userInformation: {
      handler(val) {
        if (val) {
          this.initData();
        }
      },
      deep: true,
    },
  },
  methods: {
    initData() {
      if (this.userInformation.temporaryPasswordPeek) {
        this.password = this.userInformation.temporaryPasswordPeek;
      } else {
        this.isPasswordDisplayed = false;
        this.password = '';
      }

      this.userData = JSON.parse(JSON.stringify(this.userInformation));
      this.isCredentialsBeingSentToEmail = false;
      this.isPasswordDisplayed = false;
      this.isPasswordRandomlyGenerated = false;
    },
    enablePasswordValidation() {
      this.isPasswordTextFieldFocused = true;
    },
    disablePasswordValidation() {
      this.isPasswordTextFieldFocused = false;
    },
    toggleIsPasswordDisplayed() {
      this.isPasswordDisplayed = !this.isPasswordDisplayed;
    },
    toggleIsCredentialsCopied() {
      this.isCredentialsCopied = !this.isCredentialsCopied;
    },
    validatePassword(password) {
      this.errors.password = [];
      return this.$helpers.validatePassword(password);
    },
    validateEmail(email) {
      const isEmailRequired = true;

      this.errors.email = [];
      return this.$helpers.validateEmail(email, isEmailRequired);
    },
    save() {
      const url = 'update-password?format=json';
      const params = {
        companyId: this.companyIdFromRoute,
        employeeId: this.userData.id,
        isCredentialsBeingSentToEmail: this.isCredentialsBeingSentToEmail,
        isPasswordRandomlyGenerated: this.isPasswordRandomlyGenerated,
        loaderText: 'Loading...',
        newPassword: this.password,
      };

      this.$axios.post(url, params).then(() => {
        this.$emit('refresh');
      });
    },
    generatePassword() {
      this.isCredentialsBeingSentToEmail = true;
      if (!this.isPasswordDisplayed) {
        this.toggleIsPasswordDisplayed();
      }

      if (this.isCredentialsCopied) {
        this.toggleIsCredentialsCopied();
      }

      const numLetters = 6;
      this.password = PasswordGenerator.generate(
        numLetters,
        this.$constants.PASSWORD_VALIDATION_REGEX,
      );

      this.isPasswordRandomlyGenerated = true;
    },
    async copyCredentialsToUsersClipboard() {
      let text = `Username: ${this.userData.username}`;

      if (this.password) {
        text += `\nPassword: ${this.password}`;
      }

      text += `\nLog in at ${process.env.VUE_APP_CREDENTIALS_EMAIL_URL}`;

      await navigator.clipboard.writeText(text).then(() => {
        this.toggleIsCredentialsCopied();
      });
    },
    detectIfRandomPassword(e) {
      /**
       * Without key check, when user would copy credentials from the text field,
       * it would detect that command a.k.a. META (or other non-'normal' keys were detected)
       * and the program would think a user is updating their passsword due to key press.
       * META + C or other key commbinations are not detected as individual key inputs (META then C)
       * And it will only be detected as META
       */

      // Prevents errors from flooding console when Loading page
      if (!e.key) {
        return;
      }

      if (!e.key.length) {
        return;
      }

      if (e.key.length == 1) {
        this.isPasswordRandomlyGenerated = false;
        this.isCredentialsBeingSentToEmail = false;
      }
    },
  },
};
</script>
