<template>
  <v-container fluid>
    <v-row v-if="isLoginAsUserShowing">
      <v-col align="right">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="loginAsUser()"
              class="msaBlue white--text"
              data-testid="login-as-user"
            >
              <v-icon>mdi-account-key-outline</v-icon>
            </v-btn>
          </template>
          <span>Login</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-text v-if="isCreatingUser">
            <v-row>
              <v-col align="center">
                <UserEditModal
                  ref="createUserMenu"
                  class="pt-6"
                  :employeeId="employeeId"
                  :userInformation="edited"
                  :isWebAdmin="isWebAdmin"
                  :isUserProfile="isUserProfile"
                  :isCreatingUser="isCreatingUser"
                  :isInternalAdmin="isInternalAdmin"
                  :companyIdFromRoute="companyIdFromRoute"
                  @closeDialog="isDialogVisible = false"
                  @makeStateless="isModalStateless = true"
                  @removeStateless="isModalStateless = false"
                  @refresh="initData()"
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text v-else>
            <v-row v-if="isUserProfileNonEditable" justify="center" dense>
              <v-col cols="12" md="5" lg="4">
                <v-alert type="warning" dense class="mb-0">
                  Profile editing has been disabled by your web admin.
                </v-alert>
              </v-col>
            </v-row>

            <v-row class="mt-0">
              <!-- profile picture -->
              <v-col
                :class="{ shrink: !$vuetify.breakpoint.xs }"
                :cols="$vuetify.breakpoint.xs ? 12 : false"
                v-if="!isUserProfile"
              >
                <v-row no-gutters>
                  <v-col>
                    <ProfilePicture :userInformation="edited" class="ml-0" />
                  </v-col>
                  <v-col
                    v-if="!isEditingDisabled && $vuetify.breakpoint.xs"
                    align="right"
                    @click="toggleIsDialogVisible('top')"
                  >
                    <v-btn text color="msaBlue"> EDIT </v-btn>
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                cols="12"
                :sm="isUserProfile ? false : 9"
                :md="isUserProfile ? false : 10"
              >
                <v-row :justify="isUserProfile ? 'center' : undefined">
                  <!-- user information -->
                  <v-col cols="12" md="5" lg="4">
                    <UserInformation
                      @refresh="initData()"
                      :userInformation="edited"
                      :isUserProfile="isUserProfile"
                      :isUserDisabled="isUserDisabled"
                      :isEditingDisabled="isEditingDisabled"
                      :iconTextRowSpacing="styles.iconTextRowSpacing"
                      @toggleIsDialogVisible="toggleIsDialogVisible($event)"
                    />
                    <v-divider></v-divider>
                    <CredentialsSection
                      :isUserDisabled="isUserDisabled"
                      :companyIdFromRoute="companyIdFromRoute"
                      :userInformation="edited"
                      @refresh="initData()"
                    />
                    <v-divider v-if="isGroupsShowing"></v-divider>
                    <GroupsSection
                      v-if="isGroupsShowing"
                      :isInternalAdmin="isInternalAdmin"
                      :isWebAdmin="isWebAdmin"
                      :userInformation="edited"
                      :isEditingDisabled="isEditingDisabled"
                      @toggleIsDialogVisible="toggleIsDialogVisible($event)"
                    />
                  </v-col>

                  <!-- vertical divider -->
                  <v-col
                    :class="{
                      shrink: $vuetify.breakpoint.mdAndUp,
                      grow: $vuetify.breakpoint.smAndDown,
                    }"
                    v-if="isEmergencyContactsDisplayed"
                  >
                    <v-divider
                      :vertical="$vuetify.breakpoint.mdAndUp"
                    ></v-divider>
                  </v-col>

                  <!-- assigned section -->
                  <v-col
                    cols="12"
                    md="6"
                    lg="7"
                    v-if="loaded && isEmergencyContactsDisplayed"
                  >
                    <AssignedSection
                      v-if="!isUserProfile"
                      :employeeId="employeeId"
                      :companyIdFromRoute="companyIdFromRoute"
                      :isInternalAdmin="isInternalAdmin"
                      :key="assignedSectionKey"
                    />
                    <v-divider v-if="!isUserProfile"></v-divider>
                    <NotesSection
                      v-if="!isUserProfile"
                      :isInternalAdmin="isInternalAdmin"
                      :key="notesUpdateKey"
                      :isUserDisabled="isUserDisabled"
                      @refreshNotes="refreshNotesSection"
                    />
                    <v-divider v-if="!isUserProfile"></v-divider>
                    <EmergencyContacts
                      :employeeId="employeeId"
                      :userStatus="edited.status"
                      :isUserDisabled="isUserDisabled"
                      :isEditingDisabled="isEditingDisabled"
                      :isInternalAdmin="isInternalAdmin"
                      :companyIdFromRoute="companyIdFromRoute"
                      :iconTextRowSpacing="styles.iconTextRowSpacing"
                      :key="emergencyContactsSectionKey"
                      @refreshContacts="refreshContactsSection"
                      @hideEmergencyContacts="hideEmergencyContacts"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Stateless prevents modal from closing if error is returned from backend on save -->
    <v-navigation-drawer
      right
      app
      temporary
      width="750"
      v-model="isDialogVisible"
      :stateless="isModalStateless ? true : false"
      v-if="!isCreatingUser"
      ref="editModalDrawer"
    >
      <UserEditModal
        :userInformation="edited"
        :isWebAdmin="isWebAdmin"
        :isUserProfile="isUserProfile"
        :isInternalAdmin="isInternalAdmin"
        :employeeId="employeeId"
        :companyIdFromRoute="companyIdFromRoute"
        :scrollToRef="scrollToRef"
        :drawerContainer="editModalDrawerContainer"
        :isDialogVisible="isDialogVisible"
        @closeDialog="isDialogVisible = false"
        @makeStateless="isModalStateless = true"
        @removeStateless="isModalStateless = false"
        @refresh="initData()"
      />
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import CredentialsSection from '@/components/UserDetailsRevamped/CredentialsSection.vue';
import EmergencyContacts from '@/components/UserDetailsRevamped/EmergencyContacts.vue';
import AssignedSection from '@/components/UserDetailsRevamped/AssignedSection.vue';
import UserInformation from '@/components/UserDetailsRevamped/UserInformation.vue';
import GroupsSection from '@/components/UserDetailsRevamped/GroupsSection.vue';
import ProfilePicture from '@/components/UserDetailsRevamped/ProfilePicture';
import NotesSection from '@/components/UserDetailsRevamped/NotesSection.vue';
import UserEditModal from '@/components/UserDetailsRevamped/UserEditModal.vue';

import { configureUser } from '@/global/functions.js';

export default {
  name: 'UserDetailsRevampedPage',
  components: {
    UserEditModal,
    NotesSection,
    GroupsSection,
    ProfilePicture,
    UserInformation,
    AssignedSection,
    EmergencyContacts,
    CredentialsSection,
  },
  data() {
    return {
      styles: {
        horizontalDividerClass: ['my-6'],
        iconTextRowSpacing: 'my-n4', // Used in components
      },
      notesUpdateKey: 999, // Counts up
      emergencyContactsSectionKey: 998, // Counts down
      assignedSectionKey: 9996, // Counts down
      userInformation: {},
      userCustomFields: [],
      edited: {
        id: -1,
        phone: '',
        title: '',
        email: '',
        status: -1,
        lastName: '',
        hireDate: '',
        username: '',
        createdAt: '',
        isManager: -1,
        companyId: -1,
        firstName: '',
        isEditable: -1,
        isSupervisor: -1,
        isSupervising: -1,
        isWebAppEnabled: -1,
        isWebAdministrator: -1,
        isMySafetyAppEnabled: -1,
        requirePasswordReset: -1,
        isGroupAdministrator: -1,
        temporaryPasswordPeek: '',
        allLabels: [],
        attachedLabelIds: [],
        allSupervisors: [],
        attachedSupervisorIds: [],
        companyGroupId: 0,
      },
      isDialogVisible: false,
      loaded: false,
      isModalStateless: false,
      scrollToRef: null,
      editModalDrawerContainer: null,
      isEmergencyContactsDisplayed: true,
    };
  },
  computed: {
    isGroupsShowing() {
      const isNormalUser =
        this.$store.getters.user.type == this.$constants.USER_TYPES.NORMAL_USER;

      if (!this.isUserProfile || !isNormalUser) {
        return true;
      }

      const hasPermissions = this.isWebAdmin || this.edited.isSupervisor;
      if (!hasPermissions) {
        return false;
      }

      return true;
    },
    isNotesShowing() {
      if (this.isInternalAdmin) {
        return false;
      }

      if (this.$route.name == 'IAUserDetails') {
        return false;
      }

      return true;
    },
    isLoginAsUserShowing() {
      return (
        this.isInternalAdmin &&
        !this.isCreatingUser &&
        this.$store.getters.selectedUser.username
      );
    },
    userProfileStyling() {
      return this.isUserProfile && this.$vuetify.breakpoint.mdAndUp
        ? {
            padding: '0 20%',
          }
        : '';
    },
    isUserProfileNonEditable() {
      return (
        !this.edited.isEditable &&
        !this.$store.getters.isWebAdmin &&
        !this.$store.getters.isInternalAdmin &&
        !this.isCreatingUser
      );
    },
    companyIdFromRoute() {
      return Number(this.$route.params.companyId);
    },
    employeeId() {
      let employeeId = Number(this.$route.params.id);

      if (this.isUserProfile) {
        employeeId = Number(this.$store.getters.user.id);
      }

      if (employeeId) {
        return employeeId;
      }

      if (this.isCreatingUser && employeeId === 0) {
        return employeeId;
      }

      // Can occur when route hasn't been loaded or store is missing info. Used to avoid console error
      return 0;
    },
    companyId() {
      if (this.isUserProfile) {
        return this.edited.companyId;
      }

      return this.$route.params.companyId;
    },
    isCreatingUser() {
      if (this.$route.params.id == 0) {
        return true;
      }

      return false;
    },
    isWebAdmin() {
      return this.$store.getters.isWebAdmin;
    },
    isInternalAdmin() {
      return this.$store.getters.isInternalAdmin;
    },
    isUserProfile() {
      return !(
        this.$route.name == 'IAUserDetails' || this.$route.name == 'UserDetails'
      );
    },
    isUserDisabled() {
      if (this.edited.status < 0) {
        return true;
      }

      return !this.edited.status;
    },
    isEditingDisabled() {
      if (this.isUserDisabled) {
        return true;
      }

      /*
       * isEditable comes from get-user-profile on the backend. It's > 0 when company user settings is enabled
       * permits users to edit their own profiles. Value is undefined if not viewing user profile
       * Note: User profile is different from user details
       */

      if (
        this.edited.isEditable != 1 &&
        !(this.isInternalAdmin || this.isWebAdmin)
      ) {
        return true;
      }

      return false;
    },
  },
  methods: {
    hideEmergencyContacts() {
      this.isEmergencyContactsDisplayed = false;
    },
    toggleIsDialogVisible(event) {
      this.isDialogVisible = !this.isDialogVisible;
      this.editModalDrawerContainer = this.$refs[
        'editModalDrawer'
      ].$el.querySelector('div.v-navigation-drawer__content');
      this.scrollToRef = event;
    },
    refreshContactsSection() {
      this.emergencyContactsSectionKey--;
    },
    refreshNotesSection() {
      this.notesUpdateKey++;
    },
    getEmployeeDetails() {
      const params = {
        employeeId: this.employeeId,
        loaderText: 'Loading...',
      };
      const url = this.isUserProfile
        ? 'get-user-profile?format=json'
        : 'get-user-details?format=json';

      return this.$axios
        .post(url, params)
        .then((response) => {
          this.userInformation = { ...response.data };

          if (
            this.$store.getters.user.type !=
            this.$constants.USER_TYPES.NORMAL_USER
          ) {
            this.$store.commit('updateSelectedUser', {
              id: this.userInformation.id,
              username: this.userInformation.username,
              firstName: this.userInformation.firstName,
              lastName: this.userInformation.lastName,
              status: this.userInformation.status,
            });
          }

          this.edited = {
            ...this.userInformation,
            userCustomFields: this.userCustomFields,
            allLabels: this.edited.allLabels,
            allSupervisors: this.edited.allSupervisors,
            attachedLabelIds: this.edited.attachedLabelIds,
            attachedSupervisorIds: this.edited.attachedSupervisorIds,
            companyGroupId: this.edited.companyGroupId, // from getGroupAdminCompanyInfo
          };

          if (this.edited.hireDate != '') {
            this.edited.hireDate = this.$options.filters.filterAsLocalDate(
              this.edited.hireDate,
              'LLL dd, yyyy',
            );
          }

          this.edited.createdAt = this.$options.filters.filterAsLocalDate(
            this.edited.createdAt,
            'LLL dd, yyyy',
          );
        })
        .catch((error) => error);
    },
    getUserAttachables() {
      const params = {
        employeeId: this.employeeId,
        companyId: this.companyId,
        loaderText: 'Loading...',
      };

      if (this.isInternalAdmin) {
        params.companyId = this.companyIdFromRoute;
      }

      const url = 'get-all-attachables-for-user?format=json';

      return this.$axios
        .post(url, params)
        .then((response) => {
          const r = { ...response.data };

          // Prevents the user that init'd the create from being removed from 'allSupervisors'
          if (!this.isCreatingUser) {
            r.allSupervisors = r.allSupervisors.filter((supervisor) => {
              return supervisor.id !== this.employeeId;
            });
          }

          this.edited.allLabels = r.allLabels;
          this.edited.allSupervisors = r.allSupervisors;
          this.edited.attachedLabelIds = r.attachedLabelIds;
          this.edited.attachedSupervisorIds = r.attachedSupervisorIds;
        })
        .catch((error) => error);
    },
    getUsersCustomInputFields() {
      const url = 'get-input-fields-for-user?format=json';
      const params = {
        loaderText: 'Loading...',
        userId: this.employeeId,
        companyId: this.isInternalAdmin
          ? this.companyIdFromRoute
          : this.$store.getters.user.companyId,
      };

      return this.$axios.post(url, params).then((response) => {
        this.userCustomFields = response.data;
      });
    },
    getGroupAdminCompanyInfo() {
      var params = {
        loaderText: 'Loading...',
      };
      params.companyId = this.$store.getters.isInternalAdmin
        ? this.$store.getters.selectedCompany.id
        : this.$store.getters.user.companyId;

      if (
        this.$store.getters.user.type == this.$constants.USER_TYPES.NORMAL_USER
      ) {
        return new Promise((resolve) => {
          resolve();
        });
      } else {
        return this.$axios
          .post('get-group-admin-company-info?format=json', params)
          .then((response) => {
            this.edited.companyGroupId = response.data.companyGroupId;
          })
          .catch((error) => error);
      }
    },
    loginAsUser() {
      const url = 'login-as-user?format=json';
      const params = {
        username: this.$store.getters.selectedUser.username,
        loaderText: 'Loading...',
      };

      this.$axios
        .post(url, params)
        .then((response) => {
          this.$store.commit('invalidateStore');
          var user = configureUser(this, response.data);
          var token = response.data.token;
          this.$store.commit('updateUser', user);
          this.$store.commit('updateJwtToken', token);
          this.$store.commit('updateSelectedCompany', {
            id: user.companyId,
            name: user.companyName,
            status: 1,
            companyGroupId: user.companyGroupId,
          });

          this.$router.replace({ name: 'UserHome' });
        })
        .catch((error) => error);
    },
    hasAccess() {
      // Prevents normal users from viewing 1Life account
      const is1LifeUser =
        this.$store.getters.user.firstName.toLowerCase() ==
        this.$constants.ONE_LIFE_ACCOUNT.FIRST_NAME;

      const isViewing1LifeAccount =
        this.userInformation.firstName.toLowerCase() ==
        this.$constants.ONE_LIFE_ACCOUNT.FIRST_NAME;

      if (this.isInternalAdmin || is1LifeUser) {
        return true;
      }

      if (isViewing1LifeAccount) {
        return false;
      }

      return true;
    },
    initData() {
      this.getGroupAdminCompanyInfo()
        .then(() => {
          this.getUserAttachables()
            .then(() => {
              this.getUsersCustomInputFields().then(() => {
                if (!this.isCreatingUser) {
                  this.getEmployeeDetails()
                    .then(() => {
                      if (this.hasAccess()) {
                        this.assignedSectionKey--;
                        this.loaded = true;
                      } else {
                        this.$router.push({
                          name: 'UserHome',
                        });
                      }
                    })
                    .catch((error) => error);
                } else {
                  this.edited.userCustomFields = this.userCustomFields;

                  this.loaded = true;
                  this.$refs.createUserMenu.initData();
                }
              });
            })
            .catch((error) => error);
        })
        .catch((error) => error);

      this.isModalStateless = false;
      this.isDialogVisible = false;
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
