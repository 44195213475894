<template>
  <v-avatar size="96">
    <div :style="styling.obj" :class="styling.class" v-if="!displayImage">
      <h1 class="font-weight-regular">{{ intials.toUpperCase() }}</h1>
    </div>
  </v-avatar>
</template>

<script>
export default {
  name: 'ProfilePicture',
  components: {},
  data() {
    return {
      styling: {
        obj: {
          color: 'white',
          width: '100%',
          height: '100%',
        },
        class: [
          'light-blue lighten-2',
          'd-flex',
          'justify-center',
          'align-center',
        ],
      },
      image: '',
      edited: {
        userDetails: {
          firstName: '',
          lastName: '',
        },
      },
      displayImage: false,
    };
  },
  computed: {
    intials() {
      if (!this.edited.firstName && !this.edited.lastName) {
        return '';
      }

      return `
        ${this.edited.firstName[0]}${this.edited.lastName[0]}
      `;
    },
  },
  watch: {
    userInformation: {
      handler(val) {
        if (val) {
          this.initData();
        }
      },
      deep: true,
    },
  },
  props: {
    userInformation: Object,
  },
  methods: {
    initData() {
      this.edited = JSON.parse(JSON.stringify(this.userInformation));
    },
  },
};
</script>
