<template>
  <v-container fluid v-if="api.enabled" class="pa-0">
    <v-row>
      <v-col>
        <v-text-field
          outlined
          readonly
          hide-details
          label="Token"
          v-model="api.token"
          :disabled="api.employeeDeleted"
        >
          <template v-slot:append>
            <v-tooltip bottom :disabled="isCopied">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  :disabled="disableAction || isCopied"
                  @click="copyToken()"
                  class="ml-4"
                >
                  <span v-if="isCopied" class="mb-1 mr-2 green--text">
                    Copied
                  </span>
                  <v-icon color="msaBlue" v-if="!isCopied">
                    mdi-content-copy
                  </v-icon>
                </v-btn>
              </template>
              <span>Copy</span>
            </v-tooltip>
          </template>
          <template v-slot:append-outer>
            <div
              class="d-flex flex-row mr-3 mt-n1"
              :style="$vuetify.breakpoint.xs ? '' : { position: 'absolute' }"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-on="on"
                    v-bind="attrs"
                    :disabled="api.employeeDeleted"
                    @click="
                      api.token == ''
                        ? refreshToken()
                        : (refreshTokenConfirm = true)
                    "
                  >
                    <v-icon color="msaBlue">mdi-autorenew</v-icon>
                  </v-btn>
                </template>
                <span>Generate</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    small
                    :disabled="disableAction"
                    @click="invalidateTokenConfirm = true"
                  >
                    <v-icon color="red"> mdi-api-off </v-icon>
                  </v-btn>
                </template>
                <span>Revoke</span>
              </v-tooltip>
            </div>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-dialog v-model="refreshTokenConfirm" width="400">
      <v-card>
        <v-card-title class="msaBlue white--text">
          Generate New Token
        </v-card-title>
        <v-card-text class="pt-4">
          This will revoke the current token and generate a new one.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            class="msaBlue--text"
            @click="refreshTokenConfirm = false"
          >
            Cancel
          </v-btn>
          <v-btn
            class="msaBlue white--text"
            @click="
              refreshToken();
              refreshTokenConfirm = false;
            "
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="invalidateTokenConfirm" width="400">
      <v-card>
        <v-card-title class="msaBlue white--text"> Revoke Token </v-card-title>
        <v-card-text class="pt-4">
          This will revoke the current token.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            class="msaBlue--text"
            @click="invalidateTokenConfirm = false"
          >
            Cancel
          </v-btn>
          <v-btn
            class="msaBlue white--text"
            @click="
              invalidateToken();
              invalidateTokenConfirm = false;
            "
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UserApi',
  computed: {
    disableAction() {
      return this.api.employeeDeleted || this.api.token == '';
    },
    employeeId() {
      let employeeId = parseInt(this.$route.params.id);

      if (employeeId) {
        return employeeId;
      } else {
        return this.$store.getters.user.id;
      }
    },
  },
  watch: {
    isCopied(val) {
      if (val) {
        setTimeout(() => {
          this.isCopied = false;
        }, 3000);
      }
    },
  },
  data() {
    return {
      api: {
        token: '',
        enabled: false,
        employeeDeleted: false,
      },
      isCopied: false,
      refreshTokenConfirm: false,
      invalidateTokenConfirm: false,
    };
  },
  methods: {
    copyToken() {
      this.isCopied = true;
      navigator.clipboard.writeText(this.api.token);
    },
    refreshToken() {
      this.$axios
        .post('refresh-user-api-token?format=json', {
          id: this.employeeId,
          loaderText: 'Generating new token...',
        })
        .then((response) => {
          this.api.token = response.data;
        })
        .catch((error) => error);
    },
    getEmployeeApiSettings() {
      const params = {
        id: this.employeeId,
        jwtToken: this.$store.getters.jwtToken,
      };

      // Avoids break in initial loading
      const promise = axios.create({
        baseURL: process.env.VUE_APP_BASE_URL,
        useWithCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      });
      promise
        .post('get-user-api-settings?format=json', params, {
          withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
        })
        .then((response) => {
          this.api.enabled = !!response.data.enabled;
          this.api.token = response.data.token;
          this.api.employeeDeleted = !!response.data.employeeDeleted;
          this.$emit('isApiEnabled', this.api.enabled);
        });
    },
    invalidateToken() {
      this.$axios
        .post('invalidate-user-api-token?format=json', {
          id: this.employeeId,
          loaderText: 'Revoking...',
        })
        .then(() => {
          this.api.token = '';
        })
        .catch((error) => error);
    },
  },
  mounted() {
    this.getEmployeeApiSettings();
  },
};
</script>

<style></style>
