<template>
  <v-text-field
    v-model.trim="date"
    @click:clear="fixNull"
    :rules="rules"
    :disabled="isTextFieldDisabled"
    :hide-details="hideTextFieldDetails"
    :label="label"
    :dense="isTextFieldDense"
    outlined
    clearable
    hint="Date must be in format YYYY-MM-DD (e.g. 2024-01-30)"
  >
    <template v-slot:append>
      <v-menu
        :nudge-left="150"
        :close-on-content-click="closeOnContentClick"
        dense
        offset-y
        min-width="auto"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-on="on"
            v-bind="attrs"
            :disabled="isCalendarDisabled"
            icon
            class="mt-n1"
          >
            <v-icon>mdi-calendar</v-icon>
          </v-btn>
        </template>
        <v-date-picker
          @input="date = $event"
          :value="isCalendarDisabled ? '' : date"
          :min="min || $constants.DATE_SELECTOR_RANGE.MIN"
          :max="max || $constants.DATE_SELECTOR_RANGE.MAX_EXCLUSIVE"
          no-title
          hide-details
        />
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
import { DateTime } from 'luxon';

export default {
  name: 'DateTextField',
  props: {
    closeOnContentClick: {
      type: Boolean,
      default: false,
    },
    hideTextFieldDetails: {
      type: [String, Boolean],
      default: undefined,
    },
    initValue: {
      type: String,
      default: '',
    },
    isCalendarDisabled: {
      type: Boolean,
      required: true,
    },
    isTextFieldDense: {
      type: Boolean,
      default: false,
    },
    isTextFieldDisabled: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    min: {
      type: String,
    },
    max: {
      type: String,
    },
    rules: {
      type: Array,
    },
  },
  watch: {
    date() {
      this.$emit('onInput', this.date);
    },
  },
  data() {
    return {
      date: '',
      initVal: '',
    };
  },
  methods: {
    fixNull() {
      setTimeout(() => {
        this.date = '';
        this.$emit('onInput', '');
      }, 50);
    },
  },
  updated() {
    if (this.initValue) {
      const parsedDate = DateTime.fromFormat(this.initValue, 'yyyy-MM-dd');
      if (!parsedDate.isValid) {
        return;
      }
      // Convert the DateTime object to 'YYYY-MM-DD' format
      this.date = parsedDate.toISODate();
    }
  },
};
</script>
