<template>
  <a
    @click="isInternalAdmin ? '' : $router.push({ name: routeName })"
    :style="{ color: '#00000099' }"
    :class="isInternalAdmin ? 'unclickable' : ''"
  >
    <div :style="styling.obj" :class="styling.class">
      <div align="right">
        <v-badge
          color="red"
          :value="totalItemsRequiringAttention"
          :content="totalItemsRequiringAttention"
          :class="badgeMargin(totalItemsRequiringAttention)"
        >
        </v-badge>
      </div>
      <div align="center" class="mt-n2">
        <span>{{ itemTitle }}</span>
        <h1 class="mt-4">{{ totalItemCount }}</h1>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  name: 'AssignedContainer',
  data() {
    return {
      styling: {
        class: [],
        obj: {
          width: '117px',
          height: '110px',
          borderRadius: '5px',
          border: '1px solid #D9D9D9',
        },
      },
    };
  },
  methods: {
    badgeMargin(val) {
      const baseMargin = 'mt-5 mr-';
      const numDigits = Math.floor(val).toString().length;
      const minMargin = 5;
      let calculatedMargin = baseMargin;

      // If number is single digits, have mr-5. If not single digits, add 1 additional margin for every digit
      if (numDigits < 2) {
        return (calculatedMargin += minMargin);
      }

      return (calculatedMargin += minMargin + numDigits);
    },
  },
  props: {
    itemTitle: String,
    totalItemCount: Number,
    routeName: String,
    totalItemsRequiringAttention: Number,
    isInternalAdmin: Boolean,
  },
};
</script>

<style scoped>
.unclickable:hover {
  cursor: unset;
}
</style>
