<template>
  <!-- v-if is to hide section from internal admin because they can't edit content in section. -->
  <v-col v-if="isNotesSectionShowing" cols="12">
    <v-row>
      <v-col class="text-h6 black--text">Notes</v-col>
    </v-row>

    <v-row class="mt-0" v-if="isNotesDisplayed">
      <template v-for="note in notes">
        <v-col
          cols="12"
          :key="note.id"
          @click="isUserDisabled || isInternalAdmin ? '' : prepEdit(note.id)"
          :style="{ 'word-break': 'break-word', cursor: 'pointer' }"
        >
          <span class="font-weight-medium">{{ noteHeader(note) }}</span>
          {{ note.comments | limitNoteWhileViewing }}
        </v-col>
      </template>
    </v-row>

    <v-row v-else>
      <v-col>
        <v-textarea
          rows="1"
          autofocus
          auto-grow
          :label="noteLabel"
          row-height="15"
          class="text-wrap"
          :rules="rules.comments"
          v-model.trim="noteFields.comments"
          maxlength="2000"
          counter="2000"
        >
          <template v-slot:append-outer>
            <v-btn icon v-if="!isAdding" @click="deleteNote()" color="red">
              <v-icon> mdi-delete-outline </v-icon>
            </v-btn>
          </template>
        </v-textarea>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-if="isNotesDisplayed && !isInternalAdmin">
        <v-btn
          text
          @click="prepAdd()"
          v-if="isShowActionButton"
          color="msaBlue"
          class="ml-n4"
        >
          <v-icon>mdi-text-box-plus-outline</v-icon>
          {{ noteActionLabel }}
        </v-btn>
        <v-btn
          text
          color="msaBlue"
          @click="$router.push({ name: 'UserNotes' })"
          v-if="isNotesDisplayed && notes.length > 0"
        >
          <v-icon>mdi-text-search</v-icon>
          more notes
        </v-btn>
      </v-col>
      <v-col v-if="!isNotesDisplayed">
        <v-btn
          @click="saveNote()"
          :disabled="!isShowActionButton"
          class="msaBlue white--text mr-2"
        >
          Save
        </v-btn>
        <v-btn text @click="isNotesDisplayed = true" color="red">
          <v-icon>mdi-note-remove-outline</v-icon>
          cancel
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: 'NotesSection',
  data() {
    return {
      notes: [
        {
          id: -1,
          comments: '',
          createdAt: '',
          updatedAt: '',
          updatedBy: -1,
          updatedByName: '',
        },
      ],
      rules: {
        comments: [(v) => this.$helpers.required(v, 'Comments')],
      },
      noteFields: {
        id: '',
        name: '',
        comments: '',
        noEditComments: '',
      },
      isAdding: false,
      isModifying: false,
      isNotesDisplayed: true,
    };
  },
  computed: {
    isShowActionButton() {
      // If the notes are displaying or if there is anything to save while user is creating/updating note

      return (
        (!this.isUserDisabled && this.isNotesDisplayed) ||
        (this.noteFields.comments.length && this.isModifying)
      );
    },
    isNotesSectionShowing() {
      return this.isInternalAdmin && this.notes.length < 1 ? false : true;
    },
    noteHeader() {
      return (note) => {
        if (!note) {
          return '';
        }
        const updatedAt = this.$options.filters.filterAsLocalDate(
          note.updatedAt,
          'LLL dd, yyyy',
        );
        let message = updatedAt + '. ';
        message += note.updatedByName + ' - ';

        return message;
      };
    },
    noteLabel() {
      return this.isAdding ? 'Add Note' : 'Edit Note';
    },
    noteActionLabel() {
      return this.isNotesDisplayed ? 'ADD NOTE' : 'SAVE NOTE';
    },
  },
  filters: {
    limitNoteWhileViewing(val) {
      const maxLength = 200;

      if (val.length > maxLength) {
        return val.slice(0, maxLength) + '...';
      }

      return val;
    },
  },
  props: {
    isUserDisabled: Boolean,
    isInternalAdmin: Boolean,
  },
  methods: {
    setToModifyMode() {
      this.isModifying = true;
      this.isNotesDisplayed = false;
    },
    setToViewMode() {
      this.isModifying = false;
      this.isNotesDisplayed = true;
      this.clearContactFields();
      this.isAdding = false;
    },
    getNotes() {
      const params = {
        loaderText: 'Loading...',
        maxRecords: 5,
      };
      if (this.$route.name == 'UserProfile') {
        params.employeeId = this.$store.state.user.id;
      } else {
        params.employeeId = this.$route.params.id;
      }
      const url = 'get-recent-employee-notes?format=json';

      this.$axios
        .post(url, params)
        .then((response) => {
          this.notes = response.data;
        })
        .catch((error) => error);
    },
    prepEdit(noteId) {
      this.isAdding = false;
      this.isModifying = true;
      this.isNotesDisplayed = false;

      const index = this.notes.findIndex((n) => noteId === n.id);

      this.noteFields.id = this.notes[index].id;
      this.noteFields.comments = this.notes[index].comments;
      this.noteFields.noEditComments = this.notes[index].comments;
    },
    prepAdd() {
      this.isAdding = true;
      this.isModifying = true;
      this.isNotesDisplayed = false;
      this.clearNoteFields();
    },
    saveNote() {
      const params = JSON.parse(JSON.stringify(this.noteFields));
      params.loaderText = 'Saving...';
      params.employeeId = this.$route.params.id;

      const url = this.isAdding
        ? 'add-employee-note?format=json'
        : 'update-employee-note?format=json';

      this.$axios
        .post(url, params)
        .then(() => {
          this.$emit('refreshNotes');
          this.setToViewMode();
        })
        .catch((error) => error);
    },
    deleteNote() {
      const confirm = () => {
        const params = {
          loaderText: 'Deleting...',
          employeeId: this.$route.params.id,
          id: this.noteFields.id,
        };

        // If cancelling an add of a note.
        if (this.noteFields.id == '') {
          this.setToViewMode();
        }

        const url = 'delete-employee-note?format=json';
        this.$axios
          .post(url, params)
          .then(() => {
            this.$emit('refreshNotes');
            this.setToViewMode();
          })
          .catch((error) => error);
      };
      this.$root.showMessage(
        'Confirm',
        'Are you sure you want to delete this note?',
        () => confirm(),
        () => false,
        'Confirm',
        'Cancel',
      );
    },
    clearNoteFields() {
      this.noteFields = {
        comments: '',
        name: '',
        id: '',
      };
    },
  },
  mounted() {
    this.getNotes();
  },
};
</script>
