<template>
  <v-col cols="12">
    <v-row>
      <v-col class="text-h6 pb-6 black--text"> Activity </v-col>
    </v-row>
    <v-row dense>
      <v-col v-if="isLoading" class="d-flex flex-row flex-wrap" cols="12">
        <!-- Hard coded array [1, 2, 3, 4] due to for count boxes -->
        <v-skeleton-loader
          v-for="loader in [1, 2, 3, 4]"
          :key="loader.id"
          :style="styling.loader"
          type="card"
          class="mr-2 mb-2"
        ></v-skeleton-loader>
      </v-col>
      <v-col class="d-flex flex-row flex-wrap">
        <AssignedContainer
          v-for="item in assigned"
          :key="item.id"
          :itemTitle="item.itemTitle"
          :totalItemCount="item.totalItemCount"
          :routeName="item.routeName"
          :totalItemsRequiringAttention="item.totalItemsRequiringAttention"
          :isInternalAdmin="isInternalAdmin"
          class="mr-2 mb-2"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import AssignedContainer from './AssignedContainer.vue';

export default {
  name: 'AssignedSection',
  components: {
    AssignedContainer,
  },
  data() {
    return {
      assigned: [],
      styling: {
        loader: {
          minWidth: '117px',
          maxWidth: '117px',
          minHeight: '110px',
          maxHeight: '110px',
        },
      },
      isLoading: true,
    };
  },
  methods: {
    getCounts() {
      const params = {
        companyId: this.companyIdFromRoute,
        userId: this.employeeId,
        loaderText: 'Loading...',
      };

      const url = 'get-assignables-count-for-user?format=json';

      this.$axios
        .post(url, params)
        .then((response) => {
          this.itemCounts = response.data;
        })
        .then(() => {
          this.isLoading = false;

          this.assigned = [
            {
              itemTitle: 'Forms',
              totalItemCount: this.itemCounts.allForms,
              totalItemsRequiringAttention: this.itemCounts.requiredForms,
              routeName: 'UserForms',
            },
            {
              itemTitle: 'Docs',
              totalItemCount: this.itemCounts.allDocuments,
              totalItemsRequiringAttention: this.itemCounts.requiredDocuments,
              routeName: 'UserAssignedDocuments',
            },
            {
              itemTitle: 'Tasks',
              totalItemCount: this.itemCounts.allTasks,
              totalItemsRequiringAttention: this.itemCounts.requiredTasks,
              routeName: 'UserTasks',
            },
            {
              itemTitle: 'Training',
              totalItemCount: this.itemCounts.allTraining,
              totalItemsRequiringAttention: this.itemCounts.requiredTraining,
              routeName: 'UserTrainingRecords',
            },
          ];
        })
        .catch((error) => error);
    },
  },
  props: {
    employeeId: Number,
    companyIdFromRoute: Number,
    isInternalAdmin: Boolean,
  },
  mounted() {
    this.getCounts();
  },
};
</script>
