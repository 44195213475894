<template>
  <v-col cols="12" v-if="isEmergencyContactsShowing">
    <v-row>
      <v-col class="text-h6 black--text">Emergency Contacts</v-col>
    </v-row>

    <v-row v-for="contact in contacts" :key="contact.id" dense>
      <v-col>
        <v-hover v-slot="{ hover }" v-if="isContactsDisplayed">
          <v-row align="center">
            <v-col class="grow">
              <v-row dense>
                <v-col cols="12">
                  <v-row>
                    <v-col class="shrink">
                      <v-icon>mdi-account-outline</v-icon>
                    </v-col>
                    <v-col :style="{ 'word-break': 'break-word' }">
                      {{ contact.name }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row>
                    <v-col class="shrink">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on">
                            mdi-account-multiple-outline
                          </v-icon>
                        </template>
                        <span>Relation to User</span>
                      </v-tooltip>
                    </v-col>
                    <v-col :style="{ 'word-break': 'break-word' }">
                      {{ contact.relationToUser }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col>
                  <v-row>
                    <v-col class="shrink">
                      <v-icon>mdi-phone-outline</v-icon>
                    </v-col>
                    <v-col>
                      {{ contact.phone }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              v-if="
                (hover || $vuetify.breakpoint.smAndDown) &&
                !isInternalAdmin &&
                !isUserDisabled &&
                !isEditingDisabled
              "
              class="shrink"
              :style="{ 'min-width': '110px' }"
            >
              <v-btn icon color="msaBlue" @click="prepToEditContact(contact)">
                <v-icon> mdi-pencil-outline </v-icon>
              </v-btn>
              <v-btn
                icon
                color="red"
                @click="removeContact(contact)"
                class="ml-2"
              >
                <v-icon> mdi-delete-outline </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-hover>
      </v-col>

      <v-col cols="12">
        <v-divider class="my-4" v-if="isContactsDisplayed"></v-divider>
      </v-col>
    </v-row>

    <v-row v-if="!isInternalAdmin" dense>
      <v-col cols="12" v-if="!isContactsDisplayed">
        <v-text-field
          class="pb-3"
          hide-details="auto"
          outlined
          v-model="contactFields.name"
          :rules="rules.name"
          required
          maxLength="150"
        >
          <template v-slot:label>
            <span>Name</span><span class="red--text">*</span>
          </template>
        </v-text-field>
        <v-text-field
          class="pb-3"
          hide-details="auto"
          outlined
          :rules="rules.relationToUser"
          v-model="contactFields.relationToUser"
          placeholder="Parent, Partner, Child, etc."
          maxLength="64"
        >
          <template v-slot:label>
            <span>Relation</span><span class="red--text">*</span>
          </template>
        </v-text-field>
        <v-text-field
          class="pb-3"
          hide-details="auto"
          outlined
          :rules="rules.phone"
          v-model.trim="contactFields.phone"
          @keydown.prevent.space
        >
          <template v-slot:label>
            <span>Phone</span><span class="red--text">*</span>
          </template>
        </v-text-field>
      </v-col>
      <v-col v-if="isActionButtonShowing">
        <v-btn text @click="setToModifyMode" color="msaBlue" class="ml-n4">
          <v-icon>mdi-account-plus-outline</v-icon>
          add contact
        </v-btn>
      </v-col>
      <v-col v-if="!isContactsDisplayed">
        <v-btn
          @click="saveContact()"
          :disabled="isSaveButtonDisabled"
          class="msaBlue white--text mr-2"
        >
          Save
        </v-btn>
        <v-btn text @click="setToViewMode" color="red">
          <v-icon> mdi-account-remove-outline </v-icon>
          cancel
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: 'EmergencyContacts',
  data() {
    return {
      contacts: [],
      contactFields: {
        id: -1,
        name: '',
        relationToUser: '',
        phone: '',
      },
      errors: {
        name: [],
        relationToUser: [],
        phone: [],
      },
      rules: {
        name: [(v) => this.validateName(v)],
        relationToUser: [(v) => this.validateRelation(v)],
        phone: [(v) => this.validatePhone(v)],
      },
      isModifying: false,
      isContactsDisplayed: true,
      maxContacts: 3,
      contactsLoaded: false,
    };
  },
  props: {
    iconTextRowSpacing: String,
    employeeId: Number,
    isInternalAdmin: Boolean,
    isUserDisabled: Boolean,
    companyIdFromRoute: Number,
    isEditingDisabled: Boolean,
  },
  computed: {
    isActionButtonShowing() {
      return (
        this.contacts.length < this.maxContacts &&
        this.isContactsDisplayed &&
        !this.isUserDisabled &&
        !this.isInternalAdmin &&
        !this.isEditingDisabled
      );
    },
    isEmergencyContactsShowing() {
      if (!this.contactsLoaded) {
        return true;
      }

      if (this.isInternalAdmin && this.contacts.length < 1) {
        return false;
      }

      if (this.isEditingDisabled && this.contacts.length < 1) {
        this.$emit('hideEmergencyContacts');
        return false;
      }
      return true;
    },
    isSaveButtonDisabled() {
      if (this.validatePhone(this.contactFields.phone).length) {
        return true;
      }

      if (this.validateName(this.contactFields.name).length) {
        return true;
      }

      if (this.validateRelation(this.contactFields.relationToUser).length) {
        return true;
      }

      return false;
    },
  },
  methods: {
    getEmergencyContacts() {
      const url = 'get-all-emergency-contacts-for-user?format=json';
      const params = {
        loaderText: 'Loading...',
        employeeId: this.employeeId,
        companyId: this.companyIdFromRoute,
      };

      this.$axios
        .post(url, params)
        .then((response) => {
          this.contacts = response.data;
          this.contactsLoaded = true;
        })
        .catch((error) => error);
    },
    clearContactFields() {
      this.contactFields = {
        id: -1,
        name: '',
        phone: '',
        relationToUser: '',
      };
    },
    createContact() {
      const url = 'add-emergency-contact-for-user?format=json';
      const params = {
        loaderText: 'Loading...',
        employeeId: this.employeeId,
        companyId: this.companyIdFromRoute,
        name: this.contactFields.name,
        relationToUser: this.contactFields.relationToUser,
        phone: this.contactFields.phone,
      };

      this.$axios.post(url, params).then(() => {
        this.$emit('refreshContacts');
      });
    },
    removeContact(contact) {
      this.$root.showMessage(
        'Confirm',
        'Are you sure you want to delete this contact?',
        () => this.confirmRemove(contact),
        () => false,
        'Confirm',
        'Cancel',
      );
    },
    confirmRemove(contact) {
      const url = 'remove-emergency-contact-for-user';
      const params = {
        loaderText: 'Loading...',
        employeeId: this.employeeId,
        companyId: this.companyIdFromRoute,
        contactId: contact.id,
      };

      this.$axios.post(url, params).then(() => {
        this.$emit('refreshContacts');
      });
    },
    updateContact() {
      const url = 'update-emergency-contact-for-user';
      const params = {
        loaderText: 'Loading...',
        employeeId: this.employeeId,
        companyId: this.companyIdFromRoute,
        contactId: this.contactFields.id,
        name: this.contactFields.name,
        relationToUser: this.contactFields.relationToUser,
        phone: this.contactFields.phone,
      };

      this.$axios.post(url, params).then(() => {
        this.setToViewMode();
        this.$emit('refreshContacts');
      });
    },
    prepToEditContact(contact) {
      this.setToModifyMode();

      this.contactFields.id = contact.id;
      this.contactFields.name = contact.name;
      this.contactFields.relationToUser = contact.relationToUser;
      this.contactFields.phone = contact.phone;
    },
    saveContact() {
      if (this.contactFields.id == -1) {
        this.createContact();
        return;
      }

      this.updateContact();
    },
    setToModifyMode() {
      this.isModifying = true;
      this.isContactsDisplayed = false;
    },
    setToViewMode() {
      this.clearContactFields();
      this.isModifying = false;
      this.isContactsDisplayed = true;
    },
    validateName(name) {
      this.errors.name = [];
      return this.$helpers.required(name, 'Name');
    },
    validatePhone(phone) {
      const isRequired = true;

      this.errors.phone = [];
      return this.$helpers.validatePhone(phone, isRequired);
    },
    validateRelation(relationToUser) {
      const isRequired = true;

      this.errors.relationToUser = [];
      return this.$helpers.validateRelation(relationToUser, isRequired);
    },
  },
  mounted() {
    this.getEmergencyContacts();
  },
};
</script>
