<template>
  <v-col>
    <v-row dense>
      <v-col cols="12" class="d-flex justify-space-between text-h6 black--text">
        Permissions
        <v-btn
          @click="$emit('toggleIsDialogVisible', 'permissions')"
          v-if="
            $store.getters.user.type != $constants.USER_TYPES.NORMAL_USER &&
            !isEditingDisabled
          "
          text
          color="msaBlue"
          class="px-1 ma-0"
        >
          ADD/EDIT
        </v-btn>
      </v-col>

      <v-col dense cols="12" :class="styles.class">
        <v-chip
          class="mr-3 mb-3"
          :ripple="false"
          :color="styles.chip"
          v-for="permission in permissions"
          :key="permission.id"
        >
          {{ permission }}
        </v-chip>
      </v-col>
    </v-row>

    <v-row dense :class="styles.row" v-if="!$store.getters.isNormalUser">
      <v-col cols="12" class="text-h6 black--text">
        Supervisor
        <v-btn
          v-if="!showChip(attachedSupervisors) && !isEditingDisabled"
          icon
          outlined
          x-small
          color="msaBlue"
          @click="$emit('toggleIsDialogVisible', 'supervisorsDropdown')"
        >
          <v-icon small> mdi-plus </v-icon>
        </v-btn>
      </v-col>

      <v-col dense cols="12" :class="styles.class">
        <v-chip
          class="mr-3 mb-3"
          :ripple="false"
          :color="styles.chip"
          v-for="supervisor in attachedSupervisors"
          :key="supervisor.id"
        >
          {{ supervisor.fullname }}
        </v-chip>
        <v-chip
          v-if="showChip(attachedSupervisors) && !isEditingDisabled"
          color="red"
          outlined
          @click="$emit('toggleIsDialogVisible', 'supervisorsDropdown')"
        >
          Add Supervisors
        </v-chip>
      </v-col>
    </v-row>

    <v-row dense :class="styles.row" v-if="!$store.getters.isNormalUser">
      <v-col cols="12" class="text-h6 black--text">
        Groups
        <v-btn
          v-if="!showChip(attachedLabels) && !isEditingDisabled"
          outlined
          color="msaBlue"
          x-small
          icon
          @click="$emit('toggleIsDialogVisible', 'groupsDropdown')"
        >
          <v-icon small> mdi-plus </v-icon>
        </v-btn>
      </v-col>

      <v-col dense cols="12" :class="styles.class">
        <v-chip
          class="mr-3 mb-3"
          :ripple="false"
          :color="styles.chip"
          v-for="label in attachedLabels"
          :key="label.id"
        >
          {{ label.name }}
        </v-chip>
        <v-chip
          v-if="showChip(attachedLabels) && !isEditingDisabled"
          color="red"
          outlined
          @click="$emit('toggleIsDialogVisible', 'groupsDropdown')"
        >
          Add Groups
        </v-chip>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: 'GroupsSection',
  data() {
    return {
      userData: {
        isWebAdministrator: 0,
        isGroupAdministrator: 0,
        isSupervisor: 0,
        isManager: 0,
        attachedLabelIds: [],
        allLabels: [],
        attachedSupervisorIds: [],
        allSupervisors: [],
      },
      styles: {
        linkColour: 'msaBlue--text',
        class: ['d-flex', 'flex-row', 'flex-wrap'],
        row: 'my-6',
        chip: 'lightGrey',
      },
    };
  },
  watch: {
    userInformation: {
      handler(val) {
        if (val) {
          this.initData();
        }
      },
      deep: true,
    },
  },
  props: {
    userInformation: Object,
    isEditingDisabled: Boolean,
    isWebAdmin: Boolean,
    isInternalAdmin: Boolean,
  },
  computed: {
    attachedLabels() {
      const labelIdsSet = new Set(this.userData.attachedLabelIds);

      return this.userData.allLabels.filter((label) =>
        labelIdsSet.has(label.id),
      );
    },
    attachedSupervisors() {
      const supervisorIdsSet = new Set(this.userData.attachedSupervisorIds);

      return this.userData.allSupervisors.filter((supervisor) =>
        supervisorIdsSet.has(supervisor.id),
      );
    },
    permissions() {
      const tempArr = [];

      if (this.userData.isWebAdministrator) {
        tempArr.push('Web Admin');
      }
      if (this.userData.isSupervisor) {
        tempArr.push('Supervisor');
      }
      if (this.userData.isManager) {
        tempArr.push('Manager');
      }
      if (this.userData.isSafetyRep) {
        tempArr.push('Safety');
      }
      if (this.userData.isGroupAdministrator) {
        tempArr.push('Group Admin');
      }

      return tempArr;
    },
    showChip() {
      return (itemsArr) => {
        if (
          this.$store.getters.user.type ==
          this.$constants.USER_TYPES.NORMAL_USER
        ) {
          return false;
        } else {
          return !itemsArr.length;
        }
      };
    },
  },
  methods: {
    initData() {
      this.userData = JSON.parse(JSON.stringify(this.userInformation));
    },
  },
};
</script>
